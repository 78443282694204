import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { collection, getDocs } from "firebase/firestore";
import { fireDB } from "../../Firebase/FirebaseConfigData"; // Your Firebase config

interface Subheading {
  id: string;
  title: string;
}

const SubSuggest = () => {
  const [subheadings, setSubheadings] = useState<Subheading[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  // Get parentSlug from URL params (if you're using react-router v6 or above)
  const { parentSlug } = useParams<{ parentSlug: string }>();

  // useEffect hook to fetch subheadings
  useEffect(() => {
    const fetchSubheadings = async () => {
      if (!parentSlug) {
        setError("Invalid or missing slug");
        setLoading(false);
        return;
      }

      setLoading(true);
      try {
        console.log(`Fetching subheadings for parentSlug: ${parentSlug}`);

        // Fetch subheadings collection from Firestore
        const subheadingCollection = collection(fireDB, `blogPost/${parentSlug}/subheadings`);
        const subheadingSnapShot = await getDocs(subheadingCollection);

        if (subheadingSnapShot.empty) {
          setError("No subheadings found.");
        } else {
          const fetchedSubheadings = subheadingSnapShot.docs.map((doc) => ({
            id: doc.id,
            title: doc.data().title,
          })) as Subheading[];
          setSubheadings(fetchedSubheadings);
        }
      } catch (err) {
        setError("Failed to load subheadings.");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchSubheadings();
  }, [parentSlug]); // Dependency on parentSlug

  // Loading state
  if (loading) {
    return (
      <div className="text-center p-5">
        <div className="loader"></div> {/* Your custom loader */}
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <div className="relative border border-gray-300 p-4 sm:p-6 rounded-lg shadow-lg bg-white">
      <h1 className="text-2xl sm:text-3xl font-bold text-darkBlue-800 mb-6 text-center">
        Tutorials
      </h1>

      {/* List of Subheadings with Flex */}
      <div className="space-y-4">
        {subheadings.length === 0 ? (
          <p className="text-gray-500 text-center">No subheadings available.</p>
        ) : (
          subheadings.map((subheading, index) => (
            <div key={subheading.id}>
              <div
                className="cursor-pointer py-3 px-4 rounded-lg hover:underline hover:bg-gray-200 transition-all duration-200 ease-in-out"
                onClick={() => navigate(`/bloginfo/${parentSlug}/${subheading.id}`)} // Navigate to the child subheading
              >
                <p className="text-lg font-medium text-gray-700">{subheading.title}</p>
              </div>

              {/* Horizontal Line Between Subheadings */}
              {index < subheadings.length - 1 && (
                <div className="border-t border-gray-300 mt-2"></div>
              )}
            </div>
          ))
        )}
      </div>

      {/* Error Message */}
      {error && <div className="text-red-500 mt-4 text-center">{error}</div>}
    </div>
  );
};

export default SubSuggest;
