import { DiscussionEmbed } from 'disqus-react';

interface PostProps {
  slug: string;
  title: string;
}

interface DisqusCommentsProps {
  post?: PostProps;
}

function DisqusComments({ post }: DisqusCommentsProps) {
  if (!post) {
    return null; // Render nothing if post is undefined
  }

  const pageUrl = typeof window !== 'undefined' ? window.location.href : '';

  const disqusConfig = {
    url: pageUrl,
    identifier: String(post.slug), // Ensure slug is a string
    title: String(post.title),    // Ensure title is a string
  };

  console.log('pageUrl:', pageUrl);
  console.log('disqusConfig:', disqusConfig);

  return <DiscussionEmbed shortname="blog-suggestion" config={disqusConfig} />;
}

export default DisqusComments;
