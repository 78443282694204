import { useContext, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import myContext from "../../../Context/data/myContext";
import { fireDB } from "../../../Firebase/FirebaseConfigData";
import { collection, deleteDoc, doc, getDocs } from "firebase/firestore";
import logo from '../../../../assets/Header_LOGO.png';
import DotLoader from "../../../Loading/Dot Loader/Loader";

interface Blog {
  id: string;
  title: string;
  category: string;
  date: string;
}

function Dashboard() {
  const context = useContext(myContext);

  if (!context) {
    throw new Error("Dashboard must be used within a MyContextProvider");
  }

  const navigate = useNavigate();
  const [getAllBlogs, setGetAllBlogs] = useState<Blog[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const fetchBlogs = async () => {
    setIsLoading(true);
    try {
      const blogsCollection = collection(fireDB, "blogPost");
      const blogsSnapshot = await getDocs(blogsCollection);
      const blogsList = blogsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      })) as Blog[];
      setGetAllBlogs(blogsList);
    } catch (error) {
      setError("Failed to load blogs.");
      console.error("Error fetching blogs:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchBlogs();
  }, []);

  const confirmAndDelete = async (id: string | null) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this blog?"
    );
    if (confirmDelete) {
      await handleDelete(id);
    }
  };

  const handleDelete = async (id: string | null) => {
    setIsLoading(true);
    setError("");
    try {
      if (id) {
        const postDocRef = doc(fireDB, `blogPost/${id}`);
        await deleteDoc(postDocRef);
        fetchBlogs(); // Refresh the blog list after deletion
      } else {
        throw new Error("ID is required for deletion.");
      }
    } catch (error) {
      setError((error as Error).message);
    } finally {
      setIsLoading(false);
    }
  };

  const logout = () => {
    localStorage.clear();
    navigate("/");
  };

  return (
    <div className="min-h-screen bg-gradient-to-r from-white to-blue-50 py-8">
      {/* Loading & Error Message */}
      {error && <div className="text-center text-red-600 font-semibold mb-6 text-lg">{error}</div>}
      {isLoading && (
        <div className="text-center text-blue-600 font-semibold mb-6 flex justify-center items-center text-lg">
          <DotLoader />
          <span className="ml-2">Loading...</span>
        </div>
      )}

      {/* Header */}
      <div className="max-w-7xl mx-auto px-6 sm:px-8 lg:px-12 flex flex-col lg:flex-row justify-between items-center mb-12">
        <div className="mb-6 lg:mb-0">
          <img src={logo} alt="Logo" className="h-20 w-auto object-contain" />
        </div>
        <div className="text-center mb-6 lg:mb-0">
          <h1 className="text-5xl font-extrabold text-gray-800">ForumDE Admin</h1>
        </div>
        <div className="flex gap-6 flex-col lg:flex-row">
          <Link to="/createblog">
            <button className="px-8 py-4 bg-indigo-600 text-white font-semibold rounded-xl shadow-md hover:bg-indigo-700 transition duration-300 ease-in-out transform hover:scale-105 text-lg">
              Create Blog
            </button>
          </Link>
          <button
            onClick={logout}
            className="px-8 py-4 bg-red-600 text-white font-semibold rounded-xl shadow-md hover:bg-red-700 transition duration-300 ease-in-out transform hover:scale-105 text-lg"
          >
            Logout
          </button>
        </div>
      </div>

      <hr className="border-t-2 border-gray-300 mb-8" />

      {/* Table Section */}
      <div className="container mx-auto px-4 max-w-7xl my-8 bg-white shadow-xl rounded-xl overflow-x-auto">
        <table className="min-w-full table-auto text-sm text-gray-700">
          <thead className="bg-gradient-to-r from-indigo-600 to-indigo-500">
            <tr>
              <th className="px-8 py-5 text-center text-white text-xl font-semibold">S.No</th>
              <th className="px-8 py-5 text-center text-white text-xl font-semibold">Title</th>
              <th className="px-8 py-5 text-center text-white text-xl font-semibold">Category</th>
              <th className="px-8 py-5 text-center text-white text-xl font-semibold">Date</th>
              <th className="px-8 py-5 text-left text-white text-xl font-semibold">Action</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {getAllBlogs.length > 0 ? (
              getAllBlogs.map((item, index: number) => (
                <tr key={item.id} className="transition duration-300 ease-in-out hover:bg-gray-50">
                  <td className="px-8 py-5 text-center font-semibold text-gray-800 text-lg">{index + 1}</td>
                  <td className="px-8 py-5 text-center font-semibold text-gray-800 text-lg">{item.title}</td>
                  <td className="px-8 py-5 text-center font-semibold text-gray-800 text-lg">{item.category}</td>
                  <td className="px-8 py-5 text-center font-semibold text-gray-800 text-lg">{item.date}</td>
                  <td className="px-8 py-5 text-center">
                    <button
                      onClick={() => confirmAndDelete(item.id)}
                      className="px-6 py-3 bg-red-600 text-white font-semibold rounded-lg hover:bg-red-700 transition duration-300 ease-in-out text-lg"
                    >
                      Delete
                    </button>

                    <button
                      onClick={() => navigate(`/updateblog/${item.id}`)}
                      className="px-6 m-2 py-3 bg-green-600 text-white font-semibold rounded-lg hover:bg-green-700 transition duration-300 ease-in-out text-lg"
                    >
                      Update
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={5} className="text-center py-5 text-gray-500 text-lg">
                  No blogs available.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Dashboard;
