import { useState, ReactNode, useEffect } from 'react';
import MyContext from './myContext'; // Import the context created above
import { collection, onSnapshot, orderBy, query } from 'firebase/firestore';
import { fireDB } from '../../Firebase/FirebaseConfigData';

// Define the Blog type to match the structure of your blog data from Firebase
interface Blog {
    id: string;
    title: string;
    category: string;
    thumbnail: string;
    date: string;
}

// Props type for MyState component, expecting children as ReactNode
interface MyStateProps {
    children: ReactNode;
}

function MyState({ children }: MyStateProps) {
    // State to manage mode ('light' or 'dark')
    const [mode, setMode] = useState<'light' | 'dark'>('light');
    // State to manage loading status
    const [loading, setLoading] = useState(false);
    // State to store all fetched blogs
    const [getAllBlogs, setGetAllBlogs] = useState<Blog[]>([]);

    // Toggle between light and dark mode
    const toggleMode = () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
        // Change the background color based on the mode
        document.body.style.backgroundColor = mode === 'light' ? 'rgb(17, 24, 39)' : 'white';
    };

    // Function to fetch all blogs from Firestore
    const fetchAllBlogs = () => {
        setLoading(true); // Set loading to true before fetching
        const q = query(collection(fireDB, "blogPost"), orderBy('time')); // Query to fetch blogs ordered by time
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const blogArray: Blog[] = [];
            querySnapshot.forEach((doc) => {
                blogArray.push({ ...doc.data(), id: doc.id } as Blog); // Push each blog data with its ID
            });
            setGetAllBlogs(blogArray); // Set the fetched blogs to state
            setLoading(false); // Set loading to false after fetching
        });

        return () => unsubscribe(); // Cleanup on unmount
    };

    // Fetch blogs when the component mounts
    useEffect(() => {
        fetchAllBlogs();
    }, []);

    // Provide context with the current state values
    return (
        <MyContext.Provider value={{ mode, toggleMode, getAllBlogs, loading }}>
            {children}
        </MyContext.Provider>
    );
}

export default MyState;
