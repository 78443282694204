import { Navigate } from "react-router-dom";
import { ReactNode } from "react";

interface ProtectedRouteForAdminProps {
  children: ReactNode;
}

export const ProtectedRouteForAdmin = ({ children }: ProtectedRouteForAdminProps) => {
  const adminData = localStorage.getItem('admin');
  const admin = adminData ? JSON.parse(adminData) : null;

  if (admin?.user?.email === "admin2023@forumde.com") {
    return <>{children}</>;
  } else {
    return <Navigate to="/adminlogin" />;
  }
};
