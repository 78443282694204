
const JobPage = () => {
  return (
    <div>
  <iframe src="https://optimhire.com/d/Search-job-iframe?ref_code=sonu-singh&skill=&positions=&dark_color=3f73ee&light_color=3b74e8" width="100%" height="800"></iframe>
    </div>
  );
}

export default JobPage;
