
import { useParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { fireDB } from "../../../Firebase/FirebaseConfigData";
import { doc, getDoc, updateDoc, collection, query, getDocs, setDoc } from "firebase/firestore";
import { Editor } from '@tinymce/tinymce-react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function UpdateBlog() {
  const API = import.meta.env.VITE_EDITOR
  const { id } = useParams();
  const navigate = useNavigate();
  
  // Blog State
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState("");
  const [content, setContent] = useState("");
  const [date, setDate] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  // Subheading State
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [subheadings, setSubheadings] = useState<any[]>([]);
  const [subheadingData, setSubheadingData] = useState({
    title: '',
    content: '',
    slug: '', // Added slug for new subheading
  });
  const [selectedSubheading, setSelectedSubheading] = useState<string | null>(null);

  const [showPreview, setShowPreview] = useState(false);

 

  useEffect(() => {
    const fetchBlogData = async () => {
      if (id) {
        try {
          const blogDocRef = doc(fireDB, "blogPost", id);
          const blogDoc = await getDoc(blogDocRef);
          if (blogDoc.exists()) {
            const blogData = blogDoc.data();
            setTitle(blogData?.title || "");
            setCategory(blogData?.category || "");
            setContent(blogData?.content || "");
            setDate(blogData?.date || "");
            fetchSubheadings(); // Fetch subheadings
          } else {
            setError("Blog not found.");
          }
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        } catch (err) {
          setError("Failed to fetch blog data.");
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchBlogData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const fetchSubheadings = async () => {
    const subheadingRef = collection(fireDB, `blogPost/${id}/subheadings`);
    const q = query(subheadingRef);
    const querySnapshot = await getDocs(q);
    const subheadingList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setSubheadings(subheadingList);
  };

  const handleBlogUpdate = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const blogDocRef = doc(fireDB, "blogPost", id as string);
      await updateDoc(blogDocRef, {
        title,
        category,
        content,
        date,
      });
      navigate("/"); // Redirect after update
      toast.success('Blog updated successfully!');
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (err) {
      setError("Failed to update the blog.");
      toast.error('Failed to update blog');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubheadingSelect = (slug: string) => {
    const subheading = subheadings.find(sub => sub.id === slug);
    if (subheading) {
      setSubheadingData({
        title: subheading.title,
        content: subheading.content,
        slug: subheading.id,
      });
      setSelectedSubheading(slug);
    }
  };

  const handleSubheadingUpdate = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!subheadingData.title || !subheadingData.slug || !subheadingData.content) {
      toast.error('Please fill all fields');
      return;
    }

    setIsLoading(true);
    try {
      const subheadingRef = doc(fireDB, `blogPost/${id}/subheadings/${subheadingData.slug}`);
      await updateDoc(subheadingRef, {
        title: subheadingData.title,
        content: subheadingData.content,
      });
      fetchSubheadings();
      toast.success('Subheading updated successfully!');
      setSubheadingData({ title: '', content: '', slug: '' });
      setSelectedSubheading(null);
    } catch (error) {
      toast.error('Error updating subheading');
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleNewSubheading = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!subheadingData.slug || !subheadingData.title || !subheadingData.content) {
      toast.error("Please fill out all fields, including the slug.");
      return;
    }

    setIsLoading(true);
    try {
      // Create a new subheading with the provided slug as the ID
      const newSubheadingRef = doc(fireDB, `blogPost/${id}/subheadings`, subheadingData.slug);
      await setDoc(newSubheadingRef, {
        title: subheadingData.title,
        content: subheadingData.content,
        slug: subheadingData.slug,
      });

      // Reset the state for the form after creation
      setSubheadingData({
        title: '',
        content: '',
        slug: '',
      });

      // Show success toast and refresh subheading list
      toast.success('Subheading created successfully!');
      fetchSubheadings(); // Fetch the updated list of subheadings from Firebase
    } catch (error) {
      toast.error('Error creating subheading');
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const createMarkup = (content: string) => {
    return { __html: content };
  };

  if (isLoading) return <div className="text-center">Loading...</div>;

  return (
    <div className="min-h-screen bg-gray-100 py-8">
      <div className=" mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="text-3xl font-bold text-gray-800 content-center mb-6">Update Blog</h1>
        {error && <div className="text-center text-red-500 mb-4">{error}</div>}

        <div className="flex flex-col lg:flex-row space-x-6">
          {/* Blog Update Form */}
          <div className="flex-1 bg-white p-6 rounded shadow-md">
            <form onSubmit={handleBlogUpdate}>
              <div className="mb-4">
                <label htmlFor="title" className="block text-gray-700">Title</label>
                <input
                  type="text"
                  id="title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded"
                  required
                />
              </div>

              <div className="mb-4">
                <label htmlFor="category" className="block text-gray-700">Category</label>
                <input
                  type="text"
                  id="category"
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded"
                  required
                />
              </div>

              <div className="mb-4">
                <label htmlFor="content" className="block text-gray-700">Content</label>
                <Editor
                   apiKey={API} 
                  value={content}
                  onEditorChange={(newValue) => setContent(newValue)}
                  init={{
                    height: 400,
                    menubar: false,
                    plugins: 'lists link image preview',
                    toolbar: 'undo redo | styles | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | link image | preview',
                  }}
                />
              </div>

              <div className="mb-4">
                <button
                  type="button"
                  onClick={() => setShowPreview(prev => !prev)}
                  className="bg-blue-600 text-white p-2 rounded hover:bg-blue-700"
                >
                  {showPreview ? 'Hide Preview' : 'Preview Content'}
                </button>
              </div>

              {showPreview && (
                <div className="mt-4 p-4 h-full w-full bg-gray-100 rounded-lg" dangerouslySetInnerHTML={createMarkup(content)} />
              )}

              <button
                type="submit"
                className="w-full bg-green-600 text-white p-2 rounded hover:bg-green-700"
              >
                Update Blog
              </button>
            </form>
          </div>

          {/* Create New Subheading Form */}
          <div className="flex-1 bg-white p-6 rounded shadow-md mt-6 lg:mt-0">
            <h2 className="text-xl font-semibold text-gray-800">Create New Subheading</h2>
            <form onSubmit={handleNewSubheading}>
              <div className="mb-4">
                <label htmlFor="subheading-title" className="block text-gray-700">Subheading Title</label>
                <input
                  type="text"
                  id="subheading-title"
                  value={subheadingData.title}
                  onChange={(e) => setSubheadingData({ ...subheadingData, title: e.target.value })}
                  className="w-full p-2 border border-gray-300 rounded"
                  required
                />
              </div>

              <div className="mb-4">
                <h1 className=" text-red-600 text-3xl font-extrabold">**Make sure Slug ID should unique**</h1>
                <label htmlFor="subheading-slug" className="block text-gray-700">Slug (Unique Identifier)</label>
                <input
                  type="text"
                  id="subheading-slug"
                  value={subheadingData.slug}
                  onChange={(e) => setSubheadingData({ ...subheadingData, slug: e.target.value })}
                  className="w-full p-2 border border-gray-300 rounded"
                  required
                />
              </div>

              <div className="mb-4">
                <label htmlFor="subheading-content" className="block text-gray-700">Content</label>
                <Editor
                  apiKey={API}
                  value={subheadingData.content}
                  onEditorChange={(newValue) => setSubheadingData({ ...subheadingData, content: newValue })}
                  init={{
                    height: 200,
                    menubar: false,
                    plugins: 'lists link image preview',
                    toolbar: 'undo redo | bold italic | link | preview',
                  }}
                />
              </div>

              <div className="mb-4">
                <button
                  type="submit"
                  className="w-full bg-green-600 text-white p-2 rounded hover:bg-green-700"
                >
                  Create Subheading
                </button>
              </div>
            </form>
          </div>

          {/* Update Existing Subheading */}
          <div className="flex-1 bg-white p-6 rounded shadow-md mt-6 lg:mt-0">
            <h2 className="text-xl font-semibold text-gray-800">Update Subheading</h2>
            <div className="mb-4">
              <label htmlFor="select-subheading" className="block text-gray-700">Select Subheading to Update</label>
              <select
                id="select-subheading"
                onChange={(e) => handleSubheadingSelect(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded"
              >
                <option value="">--Select Subheading--</option>
                {subheadings.map((subheading) => (
                  <option key={subheading.id} value={subheading.id}>{subheading.title}</option>
                ))}
              </select>
            </div>

            {selectedSubheading && (
              <form onSubmit={handleSubheadingUpdate}>
                <div className="mb-4">
                  <label htmlFor="subheading-update-title" className="block text-gray-700">Title</label>
                  <input
                    type="text"
                    id="subheading-update-title"
                    value={subheadingData.title}
                    onChange={(e) => setSubheadingData({ ...subheadingData, title: e.target.value })}
                    className="w-full p-2 border border-gray-300 rounded"
                    required
                  />
                </div>

                <div className="mb-4">
                  <label htmlFor="subheading-update-content" className="block text-gray-700">Content</label>
                  <Editor
                    apiKey={API}
                    value={subheadingData.content}
                    onEditorChange={(newValue) => setSubheadingData({ ...subheadingData, content: newValue })}
                    init={{
                      height: 200,
                      menubar: false,
                      plugins: 'lists link image preview',
                      toolbar: 'undo redo | bold italic | link | preview',
                    }}
                  />
                </div>

                <button
                  type="submit"
                  className="w-full bg-green-600 text-white p-2 rounded hover:bg-green-700"
                >
                  Update Subheading
                </button>
              </form>
            )}
          </div>
        </div>
<br />
<br /><br />
        <ToastContainer />
      </div>
    </div>
  );
}

export default UpdateBlog;
