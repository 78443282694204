import { useState } from "react";
import { useNavigate } from "react-router";
import toast from "react-hot-toast";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../../Firebase/FirebaseConfigData";

export default function AdminLogin() {
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    // Login Function
    const login = async () => {
        if (!email || !password) {
            return toast.error("Please fill all required fields.");
        }
        try {
            const result = await signInWithEmailAndPassword(auth, email, password);
            toast.success('Login Successful');
            localStorage.setItem('admin', JSON.stringify(result));
            navigate('/dashboard');
        } catch (error) {
            toast.error('Login Failed. Please check your credentials.');
            console.log(error);
        }
    };

    // Back Button Function
    const handleBack = () => {
        navigate("/"); // Navigate to the previous page in history
    };

    return (
        <div className="flex justify-center items-center h-screen bg-gray-100">
            {/* Card */}
            <div className="w-full max-w-sm sm:max-w-md lg:max-w-lg rounded-lg shadow-xl bg-white p-8">
                {/* Card Header */}
                <div className="text-center mb-6">
                    <div className="mb-4 rounded-full border border-gray-200 bg-gray-100 p-4 inline-block">
                        <img src="https://cdn-icons-png.flaticon.com/128/727/727399.png" className="h-16 w-16" alt="Logo" />
                    </div>
                    <h4 className="text-2xl font-semibold text-gray-800">Admin Login</h4>
                </div>

                {/* Card Body */}
                <div>
                    <form className="space-y-4" onSubmit={(e) => { e.preventDefault(); login(); }}>
                        {/* Email Input */}
                        <div>
                            <label className="block text-sm font-medium text-gray-700" htmlFor="email">Email</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm px-4 py-2 focus:ring-indigo-500 focus:border-indigo-500 transition duration-150 ease-in-out"
                            />
                        </div>

                        {/* Password Input */}
                        <div>
                            <label className="block text-sm font-medium text-gray-700" htmlFor="password">Password</label>
                            <input
                                type="password"
                                id="password"
                                name="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm px-4 py-2 focus:ring-indigo-500 focus:border-indigo-500 transition duration-150 ease-in-out"
                            />
                        </div>

                        {/* Login Button */}
                        <button
                            type="submit"
                            className="mt-4 w-full py-2 bg-indigo-600 text-white rounded-md shadow-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 transition duration-200 ease-in-out"
                        >
                            Login
                        </button>
                    </form>

                    {/* Back Button */}
                    <button
                        onClick={handleBack} // Use navigate(-1) to go back
                        className="mt-4 w-full py-2 bg-gray-600 text-white rounded-md shadow-md hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 transition duration-200 ease-in-out"
                    >
                        Back
                    </button>
                </div>
            </div>
        </div>
    );
}
