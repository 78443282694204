import { createContext } from "react";

// Define a Blog type for the blog data
interface Blog {
    id: string;
    title: string;
    category: string;
    thumbnail: string;
    date: string;
}

// Define the context type to include mode, toggleMode, getAllBlogs, and loading
type MyContextType = {
  mode: 'light' | 'dark'; // Only allow 'light' or 'dark' for mode
  toggleMode: () => void;
  getAllBlogs: Blog[]; // Array of Blog objects
  loading: boolean; // Loading state for fetching blogs
} | undefined; // The context value may also be undefined initially

// Create the context with the defined type and default to undefined
const MyContext = createContext<MyContextType>(undefined);

export default MyContext;
