import BlogPostCard from '../component/blogPostCard/Blogpostcard';
import BlogNavbar from '../component/navbar/Navbar';

const MainBlogpage = () => {
  return (
    <>
    <BlogNavbar/>
    <BlogPostCard/>
  
    </>
  );
}

export default MainBlogpage;
