import Details from '../../FrontEnd/Components/Details/Page'
import ContactUsButtons from '../../FrontEnd/Components/ContactUs/ContactPage'
import Upcoming from '../../FrontEnd/Components/UpcomingCourse/Pages'
import CourseContainer from '../../FrontEnd/Components/Course/Course'
import MyCertificate from '../../FrontEnd/Components/Certificate/Certificate'
import Mentors from '../../FrontEnd/Components/Mentor/page'
import Logo_Marquee from '../../FrontEnd/Additional/LogoMarquee/LogoMarquee'
import Video from '../../FrontEnd/Components/Video/Video'
import FAQ from '../../FrontEnd/Components/Faq/FaqPage'
import CustomMarquee from '../../FrontEnd/Components/Marquee/Marquee'
import Company_Marquee from '../../FrontEnd/Additional/LogoMarquee/CompanyMarquee'



const Home = () => {
  return (
   
        <>
        
          <div className=' space-y-8'>
          <Details />
          <CustomMarquee/>
          <ContactUsButtons />
          <Upcoming />
          <CourseContainer />
          <MyCertificate />
          <Mentors />
          
          <Logo_Marquee />
          <Company_Marquee/>
       
          <Video />
          <FAQ />
          </div>
        </>
     

  
  )
}

export default Home